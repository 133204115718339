/** @format */

import Framework7 from 'framework7/framework7.esm.bundle.js'

import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'
// Import F7 Styles
import 'framework7/css/framework7.bundle.min.css'

import 'framework7-icons'

Framework7.use(Framework7Vue)
