<!-- @format -->

<template>
  <f7-appbar class="nova-appbar">
    <div class="nova-appbar__left">
      <f7-button
        panel-toggle="left"
        class="nova-appbar__menu-icon display-flex"
        icon-f7="bars"
      ></f7-button>
    </div>
    <div v-if="right" class="nova-appbar__right">
      <nav class="nova-appbar__right-language" @click="onLangChange">
        <div class="nova-appbar__right-language-text">
          {{ $t('language.text') }}
        </div>
        <div class="nova-appbar__right-language-flag">
          <img :src="flags" alt="" />
        </div>
      </nav>
    </div>
  </f7-appbar>
</template>

<script>
  export default {
    props: {
      right: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      flags() {
        return this.$i18n.locale !== 'rus'
          ? require('@/assets/images/flags/rus.png')
          : require('@/assets/images/flags/de.png')
      },
    },
    methods: {
      onLangChange() {
        this.$i18n.locale === 'de'
          ? (this.$i18n.locale = 'rus')
          : (this.$i18n.locale = 'de')
        window.dispatchEvent(new Event('resize'))
      },
    },
  }
</script>

<style lang="scss">
  .nova-appbar {
    $self: &;
    &__right {
      #{$self}__right-language {
        display: flex;
        text-transform: uppercase;
        height: $header-height;
        line-height: $header-height;
        color: $header-link;
        font-weight: $header-fontweight;
        cursor: pointer;
        $min_width: 300px;
        $max_width: 1023px;
        $min_font: 20px;
        $max_font: $header-fontsize;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        &:hover {
          color: $header-link-hover;
        }
        #{$self}__right-language-flag {
          margin-left: 20px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>
