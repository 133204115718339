var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nova-card",attrs:{"id":("mob-" + _vm.page)}},[_c('f7-card',{staticClass:"nova-card__expandable",class:_vm.$mq,attrs:{"expandable":""}},[_c('f7-card-content',{staticClass:"nova-card__content",attrs:{"padding":false}},[_c('div',{staticClass:"nova-card__bg",style:({
          background:
            'transparent url(' +
            require('@/assets/images/card_' + _vm.bg + '.jpg') +
            ') no-repeat center center',
        })}),_c('f7-card-header',{staticClass:"nova-card__header",style:({ height: 'auto' }),attrs:{"text-color":"black"}},[_c('div',{staticClass:"nova-card__headline",class:_vm.$mq},[_vm._v(" "+_vm._s(_vm.$t(("navigation." + _vm.formatPage)))+" ")]),_c('div',{staticClass:"nova-card__teaser",class:_vm.$mq},[_vm._v(" "+_vm._s(_vm.$t((_vm.page + ".teaser")))+" ")])]),_c('f7-link',{staticClass:"card-opened-fade-in nova-card__close",style:({
          position: 'absolute',
          right: '15px',
          top: '15px',
          zIndex: '2000',
        }),attrs:{"card-close":"","color":_vm.closeColor,"icon-f7":"multiply_circle_fill"}}),_c('div',{staticClass:"card-content-padding card-opened-fade-in nova-card__text",domProps:{"innerHTML":_vm._s(_vm.$t((_vm.page + ".text")))}}),_c('f7-link',{staticClass:"nova-card__close-button",attrs:{"card-close":""}},[_c('button',{staticClass:"button button-round button-fill color-black button-small",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.$t('allgemein.Schliessen'))+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }