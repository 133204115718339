<!-- @format -->

<template>
  <f7-actions id="actions-share" class="actions-share">
    <f7-actions-group class="actions-share__group">
      <f7-actions-button class="actions-share__btn actions-share__facebook"
        ><Facebook
      /></f7-actions-button>
      <f7-actions-button class="actions-share__btn actions-share__twitter"
        ><Twitter
      /></f7-actions-button>
      <f7-actions-button class="actions-share__btn actions-share__whatsapp"
        ><Whatsapp
      /></f7-actions-button>
    </f7-actions-group>
    <f7-actions-group>
      <f7-actions-button color="red">Abbrechen</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>

<script>
  import Whatsapp from 'vue-material-design-icons/Whatsapp.vue'
  import Facebook from 'vue-material-design-icons/Facebook.vue'
  import Twitter from 'vue-material-design-icons/Twitter.vue'

  export default {
    components: {
      Whatsapp,
      Facebook,
      Twitter,
    },
  }
</script>

<style lang="scss">
  .actions-share {
    $self: &;
    &__group {
      #{$self}__label {
      }
      #{$self}__btn {
        color: white;
        font-size: 2rem;
      }
      #{$self}__facebook {
        background-color: $facebook !important;
      }
      #{$self}__twitter {
        background-color: $twitter !important;
      }
      #{$self}__whatsapp {
        background-color: $whatsapp !important;
      }
    }
  }
</style>
