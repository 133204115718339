<!-- @format -->

<template>
  <f7-app id="f7body" :params="f7params">
    <NovaAppBar :right="true" />
    <NovaLeftPanel />
    <f7-view main url="/">
      <f7-page>
        <MobileTop id="mob-index" :show-kinofinder="false" />
        <MobileTrailerSizzle />

        <!-- Card Inhalt -->
        <NovaExpandableCard page="inhalt" bg="inhalt" close-color="white" />

        <!-- Card Dreharbeiten -->
        <NovaExpandableCard page="dreharbeiten" bg="dreh" close-color="black" />

        <!-- Card Fotos -->
        <div @click="$refs.pageDark.open()">
          <NovaPlainCard page="fotos" bg="fotos" />
        </div>

        <!-- Card Fotobrowser -->
        <f7-photo-browser
          :photos="images"
          theme="dark"
          type="popup"
          :popup-close-link-text="$t('allgemein.Schliessen')"
          :navbar-of-text="$t('allgemein.von')"
          ref="pageDark"
        ></f7-photo-browser>

        <NovaActionShare />
        <NovaKinostartToolbar :kinostart="kinostart" />

        <!-- <f7-button class="col" raised actions-open="#actions-share"
          >Two groups</f7-button
        > -->
        <BaseFooter :billing="true" :youtubenote="false" />
      </f7-page>
    </f7-view>
  </f7-app>
</template>

<script>
  import moment from 'moment'
  require('../plugins/framework7')
  import NovaAppBar from '@/components/F7/Appbar.vue'
  import NovaLeftPanel from '@/components/F7/LeftPanel.vue'
  import NovaExpandableCard from '@/components/F7/ExpandableCard.vue'
  import NovaPlainCard from '@/components/F7/PlainCard.vue'
  import NovaActionShare from '@/components/F7/ShareActionSheet.vue'
  import NovaKinostartToolbar from '@/components/F7/KinostartToolbar.vue'

  export default {
    components: {
      NovaAppBar,
      NovaLeftPanel,
      NovaExpandableCard,
      NovaPlainCard,
      NovaActionShare,
      NovaKinostartToolbar,
    },
    data() {
      return {
        // Framework7 parameters that we pass to <f7-app> component
        f7params: {
          name: 'Webseite', // App name
          theme: 'auto', // Automatic theme detection
        },
        images: [],
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 2) +
          `.jpg`)
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('Auth/userSignOut')
      },
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>
