<!-- @format -->

<template>
  <f7-toolbar class="kinostart-toolbar" position="bottom" no-shadow no-hairline>
    <div v-html="$t('kinostart.' + kinostart)"></div>
  </f7-toolbar>
</template>

<script>
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
  }
</script>

<style lang="scss">
  .kinostart-toolbar {
    position: fixed !important;
    background-color: $primary !important;
    .toolbar-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      /* $min_width: 300px;
      $max_width: 1023px;
      $min_font: 22px;
      $max_font: 24px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font); */
    }
  }
</style>
