<!-- @format -->

<template>
  <div :id="`mob-${page}`" class="nova-card">
    <f7-card expandable :class="$mq" class="nova-card__expandable no-click">
      <f7-card-content :padding="false" class="nova-card__content">
        <div
          :style="{
            background:
              'transparent url(' +
              require('@/assets/images/card_' + bg + '.jpg') +
              ') no-repeat center center',
          }"
          class="nova-card__bg"
        ></div>
        <f7-card-header
          text-color="black"
          :style="{ height: 'auto' }"
          class="nova-card__header"
        >
          <div class="nova-card__headline" :class="$mq">
            {{ $t(`navigation.${formatPage}`) }}
          </div>
          <div class="nova-card__teaser" :class="$mq">
            {{ $t(`${page}.teaser`) }}
          </div>
        </f7-card-header>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
  export default {
    props: {
      page: {
        type: String,
        default: 'index',
      },
      bg: {
        type: String,
        default: 'index',
      },
      closeColor: {
        type: String,
        default: 'black',
      },
    },
    computed: {
      formatPage() {
        return this.page.charAt(0).toUpperCase() + this.page.slice(1)
      },
    },
  }
</script>

<style lang="scss">
  .nova-card {
    $self: &;
    background-color: $primary !important;
    padding-bottom: 20px !important;
    cursor: pointer !important;
    &__expandable {
      height: 435px !important;
      box-shadow: none !important;
      #{$self}__content {
        #{$self}__bg {
          background-size: cover !important;
          height: 300px;
          width: 100%;
        }
        #{$self}__header {
          display: flex !important;
          flex-direction: column !important;
          align-items: flex-start;
          justify-content: flex-start !important;
          text-align: left;
          #{$self}__headline {
            color: $secondary;
            font-weight: 900;
            text-transform: uppercase;
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 24px;
            $max_font: 50px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          #{$self}__teaser {
            display: block;
            opacity: 0.7;
            font-size: 1rem;
            line-height: 1;
            margin-right: 45px;
            &.sm {
              padding-bottom: 10px;
            }
          }
        }
        #{$self}__close {
        }
        #{$self}__text {
          text-align: left;
          h1 {
            line-height: 1;
            margin-bottom: 10px;
          }
        }
        #{$self}__close-button {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 50px;
        }
      }
    }
  }
</style>
