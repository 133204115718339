<!-- @format -->

<template>
  <f7-panel @panel:closed="panelClosed" left reveal>
    <f7-block>
      <div
        class="mobile-nav"
        v-for="(value, key) in this.$config.navigation"
        :key="value.sitename"
      >
        <div
          v-if="key !== 'Trailer' && key !== 'Schulmaterial'"
          class="mobile-nav__item panel-close"
          :class="$mq"
          @click="element = 'mob-' + value.sitename"
        >
          {{ $t(`navigation.${key}`) }}
        </div>
        <div
          v-if="key === 'Schulmaterial' && $i18n.locale === 'de'"
          class="mobile-nav__item panel-close"
          :class="$mq"
          @click="download"
        >
          {{ $t(`navigation.${key}`) }}
        </div>
      </div>
      <!-- <div class="mobile-nav__kinofinder">
        <div
          v-if="$i18n.locale === 'de'"
          :href="$config.kinoservice.finder + $config.kinoservice.slug"
          target="_blank"
          class="mobile-nav__item"
          :class="$mq"
          @click="open_de_kinofinder"
        >
          {{ $t('kinoservice.Kinofinder') }}
        </div>
        <div
          v-else-if="$i18n.locale === 'rus'"
          target="_blank"
          class="mobile-nav__item"
          :class="$mq"
          @click="open_rus"
        >
          {{ $t('kinoservice.Kinofinder') }}
        </div>
      </div>
      <div class="mobile-nav__filmwecker">
        <div
          v-if="$i18n.locale === 'de'"
          target="_blank"
          class="mobile-nav__item"
          @click="open_de_filmwecker"
        >
          {{ $t('kinoservice.Filmwecker') }}
        </div>
      </div> -->
      <div class="mobile-nav__share mobile-nav__item">
        {{ $t('kinoservice.Teilen') }}
        <div class="mobile-nav__share-btns">
          <a
            target="_blank"
            :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
            class="mobile-nav__share-item mobile-nav__share-fb external"
            ><img src="@/assets/images/social/fb_plain.png" alt="" />
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
            target="_blank"
            class="mobile-nav__share-item mobile-nav__share-twitter external"
          >
            <img src="@/assets/images/social/twitter_plain.png" alt="" />
          </a>
          <a
            :href="`WhatsApp://send?text=${$config.whatsapp.text}`"
            data-action="share/whatsapp/share"
            target="_blank"
            class="mobile-nav__share-item mobile-nav__share-whatsapp external"
          >
            <img src="@/assets/images/social/whatsapp_plain.png" alt="" />
          </a>
        </div>
      </div>
      <div class="mobile-nav__besuch mobile-nav__item">
        {{ $t('allgemein.Besuche') }}
        <a
          :href="$config.facebook.link"
          target="_blank"
          class="icon-link external"
        >
          <i class="icon f7-icons">logo_facebook</i>
        </a>
      </div>
    </f7-block>
  </f7-panel>
</template>

<script>
  export default {
    methods: {
      panelClosed() {
        let tmp = document.getElementById(this.element)
        if (tmp !== null) {
          tmp.scrollIntoView({
            top: tmp.getBoundingClientRect().top,
            behavior: 'smooth',
          })
        }
      },
      download() {
        window.open('RusslandVonOben_Filmheft_final.pdf')
      },
      open_de_kinofinder() {
        window.open('https://www.kino.de/film/russland-von-oben-2019/')
      },
      open_de_filmwecker() {
        window.open('https://www.kino-zeit.de/alert/48491')
      },
      open_rus() {
        window.open('https://kinokartina.tv/movie/polyet-nad-rossiey/')
      },
    },
  }
</script>

<style lang="scss">
  .mobile-nav {
    $self: &;
    text-align: left;
    &__item {
      color: white;
      text-transform: uppercase;
      font-weight: 900;
      $min_width: 300px;
      $max_width: 1023px;
      $min_font: 20px;
      $max_font: 38px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    &__besuch {
      margin-top: 20px;
      text-align: left;
      .icon-link {
        display: block;
        color: #fff !important;
        .f7-icons {
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 40px;
          $max_font: 50px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
    }
    &__kinofinder {
      position: relative;
      text-align: left;
    }
    &__filmwecker {
      position: relative;
      text-align: left;
    }
    &__share {
      position: relative;
      text-align: left;
      #{$self}__share-btns {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        #{$self}__share-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 50px;
        }
        #{$self}__share-fb {
          background-color: $facebook;
          &:hover {
            background-color: darken($facebook, 5%);
          }
        }
        #{$self}__share-twitter {
          background-color: $twitter;
          &:hover {
            background-color: darken($twitter, 5%);
          }
        }
        #{$self}__share-whatsapp {
          background-color: $whatsapp;
          &:hover {
            background-color: darken($whatsapp, 5%);
          }
        }
      }
    }
  }
</style>
